<script>
  import { mapState } from 'vuex'
  import ArrowButton from '@/components/ArrowButton.vue'
  import GetQuote from '@/components/forms/GetQuote.vue'
  import gsap from 'gsap'

  export default {
    name: 'Detail',
    data() {
      return {
        drawer: false,
        fullGallery: false,
        currentImage: 0,
        loaded: false,
      }
    },
    props: [
      'id',
    ],
    components: {
      ArrowButton,
      GetQuote,
    },
    watch: {
      '$route': 'init',
    },
    async mounted() {
      await this.init()
    },
    computed: {
      ...mapState(['currentPage']),
      gallery() {
        if(this.currentPage && this.currentPage.gallery) {
          return this.currentPage.gallery
        }
        return []
      },
      documents() {
        if(this.currentPage && this.currentPage.documents) {
          return this.currentPage.documents
        }
        return []
      },
      themeClass() {
        return {
          greenTheme: this.currentPage.color == 'green',
          blueTheme: this.currentPage.color == 'blue',
          purpleTheme: this.currentPage.color == 'purple',
        }
      },

    },
    methods: {
      async init() {

        await this.$store.dispatch('GET_PRODUCTS')
        await this.$store.dispatch('SET_PAGE', this.id)
        this.loaded = true
        var t1 = new gsap.timeline()
        t1.from('.gallery', {
          x: -2000,
          scale: .2,
          opacity: 0,
          duration: .9,
          ease: 'expo.inOut',

        })
        t1.from('.details', {
          x: 2000,
          duration: .9,
          scale: .2,
          opacity: 0,
          ease: 'expo.inOut',
        }, '-=.9')
      },
      toggleDrawer() {
        this.drawer = !this.drawer
      },
      toggleFullGallery() {
        this.fullGallery = !this.fullGallery
        this.$store.dispatch('ui/TOGGLE_MODAL', this.fullGallery)
      },
      showModal() {
        this.$store.dispatch('ui/TOGGLE_GET_QUOTE', true)
      },
      changeGalleryImage(i) {
        this.currentImage = i
        this.toggleFullGallery()
      },
      getDocument(url) {
        window.open(url, '_blank')
      },
      documentIcon(filename) {
        switch(filename.substring(filename.lastIndexOf('.') + 1)) {
          case 'pdf':
            return 'mdi-file-pdf'
          case 'doc':
            return 'mdi-file-document'
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'tif':
          case 'gif':
            return 'mdi-file-image'
        }
      },
    },
    beforeRouteLeave(to, from, next) {
      var t1 = new gsap.timeline()
      t1.to('.gallery', {
        x: -1000,
        scale: .2,
        duration: .9,
        ease: 'circ.out',
      })
      t1.to('.details', {
        x: 1000,
        duration: .9,
        scale: .2,
        ease: 'circ.out',
      }, '-=.9').then(() => {
        next()
      })
    },
  }
</script>

<template>
  <v-row v-show="loaded" no-gutters class="fill-height pa-0 ma-0" :class="themeClass">
    <v-col class="gallery col-md-12 col-xs-12 col-sm-12 col-lg-8">
      <GetQuote></GetQuote>
      <v-row no-gutters>
        <v-col class="px-10 text-center">
          <v-carousel hide-delimiters v-model="currentImage">
            <v-carousel-item
              class="carousel-item"
              v-for="(item,i) in gallery"
              :key="i"
              :src="item.img"
            ></v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
      <div class="indicator mt-4">
        {{ currentImage + 1 }}/{{ gallery.length }}
      </div>
      <v-btn rounded @click="toggleFullGallery" class="mt-6" height="40">
        <v-icon class="mr-4">mdi-border-all</v-icon> View All Photos and Videos
      </v-btn>
    </v-col>
    <v-col class="flex-column d-flex fill-height col-md-12 col-xs-12 col-sm-12 col-lg-4 details text-left px-12">
      <v-row class="flex-row align-start">
        <v-col>
          <h1 class="mt-12 pl-3 pt-2">
            {{ currentPage.name }}
          </h1>
          <p>{{ currentPage.description }}</p>
        </v-col>
      </v-row>
      <v-row class="mainbtns flex-row align-content-end">
        <v-col :class="themeClass">
          <ArrowButton
            @click="toggleDrawer"
            icon="mdi-download-outline"
            text="Download Documents"
          >
          </ArrowButton>
          <ArrowButton
            @click="showModal"
            icon="mdi-cash-usd-outline"
            text="Get Quote"
          >
          </ArrowButton>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog
      v-model="fullGallery"
      max-width="70%"
      @click:outside="toggleFullGallery"
    >
      <v-card class="text-center">
        <v-card-text :class="themeClass" class="gallerypics pt-5 pics">
          <div class="pic mx-6 mb-3"  v-for="(item, i) in gallery" :key="i" @click="changeGalleryImage(i)">
            <img alt="" v-if="item.img || item.img == ''" :src="item.img" />
            <video v-if="item.video" :src="item.video" width="100%" height="auto"></video>
            <div class="pic-name">{{ item.name }} <v-icon small class="pic-icon">mdi-arrow-top-right</v-icon></div>
          </div>
        </v-card-text>
        <v-card-title
          class="pl-0 pt-1 mx-4 gallery-title"
          primary-title
        >
          {{ currentPage.name }} <v-icon @click="toggleFullGallery" class="galleryclose">mdi-close</v-icon>
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      class="drawer pa-0 ma-0"
      v-model="drawer"
      fixed
      temporary
      right
      width="400"
    >
      <v-list-item>
        <h1 class="pt-2 mt-8 mb-2">
          Download {{ currentPage.name }} documents <v-icon @click="toggleDrawer" class="close">mdi-close</v-icon>
        </h1>
      </v-list-item>
      <v-list-item class="document text-button" :key="i" v-for="(document, i) in documents">
        <a download :href="document.link" target="_blank">
          <v-icon class="document-icon mr-0">{{ documentIcon(document.link) }}</v-icon> {{ document.name }}
        </a>
      </v-list-item>
    </v-navigation-drawer>
  </v-row>
</template>

<style lang="scss" scoped>
  @import '@/assets/sass/theme.scss';
</style>
